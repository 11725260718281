import { type FlexProps, Flex } from '@iheartradio/web.accomplice/flex';
import { Box, media, Player, useMediaQuery } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { useDeepCompareEffect } from '@iheartradio/web.remix-shared/react/use-deep-compare-effect.js';
import { isNotBlank } from '@iheartradio/web.utilities';
import { memo, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { ClientOnly } from 'remix-utils/client-only';

import { playback } from '~app/playback/playback';

import { GoogleAds } from './ads';
import { CompanionAd } from './companion-ad';
import { handler } from './handler';
import { adClickHandler } from './nav-ad';
import { useDisplayTargeting } from './targeting/use-display-targeting';
import { useAdUnit } from './use-ad-unit';
import { useDisplayAd } from './use-display-ad';

const INLINE_AD_SIZES_MOBILE = [320, 50] as [number, number];

const INLINE_AD_SIZES_LARGE = [728, 90] as [number, number];

type InlineAdSlotProps = {
  isGrid?: boolean;
  noWrapper?: boolean;
  _section?: string;
};

export const InlineAd = memo(function InlineAd({
  isGrid = false,
  noWrapper = false,
  _section = 'default',
}: InlineAdSlotProps) {
  const isLarge = useMediaQuery(media.large);

  const flexProps: FlexProps = useMemo<FlexProps>(() => {
    return {
      alignItems: 'center',
      display: { mobile: 'flex' },
      direction: 'row',
      height: 'min-content',
      placeContent: 'center',
      position: 'relative',
      width: '100%',
      ...(isGrid ?
        {
          gridColumn: '1 / -1',
          gridRow: '2',
        }
      : {}),
    } satisfies FlexProps;
  }, [isGrid]);

  const [isFullScreen] = Player.useFullScreen();

  const adUnitPath = useAdUnit();

  const { ref, inView } = useInView({
    rootMargin: '0% 0% -20% 0%',
    triggerOnce: true,
  });

  const playerState = playback.useState();
  const playerAdsState = playback.useAds();

  const [companion, setCompanion] = useState<JSX.Element | null>(null);

  const [displayState, setDisplayState] = useState({
    displayAd: false,
    companionAd: false,
  });

  const { display } = useDisplayAd();

  const targeting = useDisplayTargeting();

  const INLINE_AD_SIZES =
    isLarge ? INLINE_AD_SIZES_LARGE : INLINE_AD_SIZES_MOBILE;

  useDeepCompareEffect(() => {
    if (
      display &&
      ((playerAdsState.status === Playback.AdPlayerStatus.Idle &&
        playerState.status === Playback.Status.Idle) ||
        playerAdsState.status === Playback.AdPlayerStatus.Done) &&
      isNotBlank(adUnitPath)
    ) {
      setDisplayState(previousProps => ({
        ...previousProps,
        displayAd: true,
      }));
    } else {
      setDisplayState(previousProps => ({
        ...previousProps,
        displayAd: false,
      }));
    }
  }, [adUnitPath, display, playerAdsState]);

  useDeepCompareEffect(() => {
    if (
      displayState.displayAd &&
      adUnitPath &&
      !displayState.companionAd &&
      !isFullScreen &&
      inView
    ) {
      GoogleAds.receiveTargeting(targeting);
      GoogleAds.addSlot(adUnitPath, INLINE_AD_SIZES, 'inline', {
        ccrpos: '2014',
      });
    }

    return () => {
      GoogleAds.removeSlot('inline');
    };
  }, [displayState, adUnitPath, targeting, isFullScreen, inView]);

  /* 
    Below code is added to capture the ad click event
    As advert is rendering in the iframe, we can't capture the click event. `blur` event fired when element/window loses focus 
    (on iframe click or link opens in new tab/window), we are capturing that plus we are making sure that active element was advert. 
    If the clicked element is advert then we are firing click event to advert container so that we can do any action(ex: firing analytics event). 
    We are ensuring `blur` event *will* fire by focusing window with `window.focus()`. Throttle is added to prevent multiple taps/clicks.
    */
  useEffect(() => {
    const thisHandler = handler({ containerId: 'inline' });

    window.focus();
    window.addEventListener('blur', thisHandler);

    return () => {
      window.removeEventListener('blur', thisHandler);
    };
  }, []);

  useDeepCompareEffect(() => {
    if (
      isNotBlank(playerAdsState.current) &&
      isNotBlank(playerAdsState.current.companions)
    ) {
      const companionToRender =
        playerAdsState.current.companions.find(companion => {
          return (
            companion.height === INLINE_AD_SIZES[1] &&
            companion.width === INLINE_AD_SIZES[0]
          );
        }) ?? null;
      if (companionToRender && !isFullScreen) {
        setCompanion(<CompanionAd companion={companionToRender} />);
        setDisplayState({
          displayAd: false,
          companionAd: true,
        });
      } else {
        setCompanion(null);
        setDisplayState(previousProps => ({
          ...previousProps,
          companionAd: false,
        }));
      }
    } else {
      setCompanion(null);
      setDisplayState(previousProps => ({
        ...previousProps,
        companionAd: false,
      }));
    }
  }, [playerAdsState.current, isFullScreen]);

  return (
    <ClientOnly>
      {() =>
        displayState.displayAd ?
          noWrapper ?
            <Box
              css={{
                height: 'min-content',
                opacity: 0,
                textAlign: 'center',
                transition: 'opacity 0.5s',
              }}
              data-section={_section ?? 'default'}
              data-test="inline-ad-slot"
              id="inline"
              onClick={adClickHandler}
              ref={ref}
              style={{
                display: 'block',
              }}
            />
          : <Flex data-test="inline-advert-container" {...flexProps}>
              <Box
                css={{
                  height: 'min-content',
                  opacity: 0,
                  textAlign: 'center',
                  transition: 'opacity 0.5s',
                }}
                data-section={_section ?? 'default'}
                data-test="inline-ad-slot"
                id="inline"
                onClick={adClickHandler}
                ref={ref}
                style={{
                  display: 'block',
                }}
              />
            </Flex>
        : displayState.companionAd ?
          noWrapper ?
            <Box
              css={{
                height: 'min-content',
                opacity: 1,
              }}
              data-section={_section ?? 'default'}
              data-test="inline-ad-companion"
              display={{ '@medium': 'block' }}
              height={INLINE_AD_SIZES[1]}
              id="inline-companion"
              opacity="1"
              width={INLINE_AD_SIZES[0]}
            >
              {companion}
            </Box>
          : <Flex data-test="inline-companion-container" {...flexProps}>
              <Box
                css={{
                  height: 'min-content',
                  opacity: 1,
                }}
                data-section={_section ?? 'default'}
                data-test="inline-ad-companion"
                display={{ '@medium': 'block' }}
                height={INLINE_AD_SIZES[1]}
                id="inline-companion"
                opacity="1"
                width={INLINE_AD_SIZES[0]}
              >
                {companion}
              </Box>
            </Flex>
        : null
      }
    </ClientOnly>
  );
});
